const TOPICMAPVERSION = '1.23.1';
const TOPICMAPHASH = '#b4ec2fa2a86d054a6de55c17bc9499c4c19312e6';

export const getTopicMapVersion = () => {
	/*eslint-disable no-useless-concat*/
	if (TOPICMAPVERSION === '%TOPICMAP' + '_' + 'VERSION%') {
		return 'dev-hot-reload';
	} else {
		return TOPICMAPVERSION;
	}
};
export const getTopicMapHash = () => {
	if (TOPICMAPHASH === '%TOPICMAP' + '_' + 'HASH%') {
		return '#dev-hot-reload';
	} else {
		return TOPICMAPHASH;
	}
};
